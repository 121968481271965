<template>
  <section class="select-present-cards">
    <div class="header-product">
      <div class="slider-product" v-if="receiveSelectGift !== null">
        <img :src="receiveSelectGift.image.url" alt="">
      </div>
      <div class="product-description">
        <h2 class="title" v-if="receiveSelectGift !== null">{{ receiveSelectGift.title }}</h2>
        <div class="price-product">
          <h4 class="price" v-if="receiveSelectGift !== null">€ {{ receiveSelectGift.price/100 }}</h4>
        </div>
        <div class="container">
          <div class="product-counter">
            <div class="minus" @click="decreaseCounter()"><img src="@/assets/img/SelectProduct/minus.svg" alt=""></div>
            <div class="counter" v-if="receiveSelectGift !== null">{{ receiveSelectGift.counter }}</div>
            <div class="plus" @click="increaseCounter()"><img src="@/assets/img/SelectProduct/plus.svg" alt=""></div>
          </div>
          <div class="container-for-button">
            <div class="btn-add-to-card" @click="addToBlank">
              <img src="@/assets/img/SelectProduct/Cart.svg" alt="">
              <h6>Add to cart</h6>
            </div>
          </div>
        </div>
        <div class="description" v-if="receiveSelectGift !== null">
          <p>
            {{ receiveSelectGift.description }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SelectGift",
  props:{
    openBasket:{
      type:Function
    }
  },
  data(){
    return{
    }
  },
  methods:{
    increaseCounter(){
      this.receiveSelectGift.counter++
    },
    decreaseCounter(){
      if(this.receiveSelectGift.counter > 1){
        this.receiveSelectGift.counter--
      }
    },
    addToBlank(){
      this.$store.dispatch('changeProductDataGift',this.receiveSelectGift)
      this.openBasket();
    }
  },
  watch:{
    '$route.params.giftId':{
      handler(giftId){
        this.$store.dispatch('getSelectGift',giftId)
      },
      deep:true,
      immediate:true,
    },
    '$route.params.productId':{
      handler(productId){
        this.$store.dispatch('getSelectProduct',productId)
      },
      deep:true,
      immediate:true,
    }
  },
  computed:{
    receiveSelectGift() {
      return this.$store.getters['receiveSelectGift']
    }
  },
}
</script>

<style scoped lang="scss">
@import "SelectGift";
</style>